
// main-section
.main-section {
    padding: 60px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section {
        padding: 80px 0;
    }
}
@media (min-width: 1024px) {
    .main-section {
        padding: 120px 0;
    }
}
@media (min-width: 1200px) {
}

// main-products
.main-products {
    .swiper-control {
        bottom: 68%;
        transform: translate(-50%, 50%);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-products {
        &:hover {
            .swiper-control {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width: 1024px) {
}
